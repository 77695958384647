<template>
  <div class="main">
    <!-- <router-link class="nav-link" to="/qrscanner"  tag="button">Jetzt QR Code Scannen</router-link>  -->





  <button class="btn btn-primary" @click="addNewEintrag()"><i class="el-icon-plus"></i></button>
<button class="btn btn-secondary" @click="reload()"><i class="el-icon-refresh"></i></button>
<button type="button" class="btn btn-secondary" @click="setMonth(-1)"><i class="el-icon-arrow-left"></i></button>
<button type="button" class="btn btn-secondary" @click="setMonth(+1)"><i class="el-icon-arrow-right"></i></button>
<button type="button" class="btn btn-secondary" @click="setGasOelBestand()"><i class="el-icon-odometer"></i></button>
 <select v-model="kategorieFilter" class="form-control">
             <option value="">Bitte Filter wählen</option>
             <option value="Service">Service</option>
             <option value="Reparaturen">Reparaturen</option>
             <option value="Unterhaltung">Unterhaltung</option>
             <option value="Halle">Halle</option>
             <option value="Defekte">Defekte</option>
             <option value="Sonstiges">Sonstiges</option>
             <option value="Büro">Büro</option>
             <option value="SUK">SUK</option>
             <option value="Gasölbestand">Gasölbestand</option>
             <option value="Bunkern">Bunkern</option>
             <option value="Mitarbeiter">Mitarbeiter</option>
             
             <option value="Arbeitsnachweis">Arbeitsnachweis</option>
             <option value="Wasserständer">Wasserständer</option>
             <option value="Hochwasser">Hochwasser</option>
             <option value="Niedrigwasser">Niedrigwasser</option>

             




           </select>
<el-date-picker
        v-model="startdatum"
        type="date"
        placeholder="Start wählen"
        format="DD.MM.YYYY"
      >
      </el-date-picker>

      <el-date-picker
        v-model="endedatum"
        type="date"
        placeholder="Ende wählen"
        format="DD.MM.YYYY"
      >
      </el-date-picker>
<input type="checkbox" class="btn btn-default"  v-model="showAllFaehren" /> Alle Fähren anzeigen

<button type="button" class="btn btn-default" > <i class="el-icon-time"></i> {{dauer}} </button>
   

  <table class="table table-striped">
    <tr>
      <th>Datum</th>
      <th>Text</th>
      <th>
        <i class="el-icon-time"></i>

      </th>
      <th>Typ</th>
    </tr>
    <tr class="cp" :key="eintrag._id" v-for="eintrag in eintraegeComputed"  v-bind:class="eintrag.kategorie" @click="goTo('/eintrag/'+eintrag._id)">
      <td>{{getDate(eintrag.datum)}}</td>
      <td>{{eintrag.text}}</td>
      <td style="text-align:right">{{eintrag.dauer}}</td>
      <td>{{eintrag.kategorie}}</td>
    </tr>
    <tr>
      <th></th>
      <th colspan="2" style="text-align:right border-top:1px solid black"> Summe: {{dauer}}</th>
      <th></th>
    </tr>
  </table>
  



    
  </div>
</template>
<script>
import { inject ,ref, computed} from "vue";
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  setup() {
    const storage = inject("storage");
    const web = inject("web");
    const moment = inject("moment");
    console.log("STUP");
    let eintraege=ref([])
    let faehre=ref()
    let kategorieFilter=ref("")
    let dauer=ref(0)
    let showAllFaehren = ref(true)

    // let startdatum = ref(moment().add(-3,"month").toDate())
    let startdatum = ref(moment().startOf("month"))
    // let endedatum = ref()
    let endedatum = ref(moment().endOf("month"))
    faehre.value=storage.get("faehre")

    function goTo(target) {
      router.push({ path: target });
    }




    const eintraegeComputed = computed(() => {
      return eintraege.value.filter(function(eintrag){
        console.log(kategorieFilter.value)
        console.log("TYP:"+eintrag.kategorie)
        console.log(eintrag.kategorie)

        if(kategorieFilter.value==""){

          // Gasölbestand Soll nicht oihne Filter angezeigt werden
         if(eintrag.kategorie=="Gasölbestand") return false;

         return true;
        }
        
        return kategorieFilter.value==eintrag.kategorie

        // return true
      }
        
        // auftragsnummern => auftragsnummern.checked_rl
      );
    });

    function addNewEintrag(){

       web.post("/addNewEintrag",{faehre:faehre.value},(data) => {
        console.log("addNewEintrag");
        console.log(data);
        eintraege.value.unshift(data.neuerEintrag)
        goTo('/eintrag/'+data.neuerEintrag._id)
        
      })
    }
  
    function setGasOelBestand(){
      var menge=prompt("Bitte den Stand angeben")

      if(menge==null || menge=="") return;
      
       web.post("/addNewEintrag",{faehre:faehre.value,text:menge,kategorie:"Gasölbestand"},(data) => {
        eintraege.value.unshift(data.neuerEintrag)
      })

    }
    function reload(){
      web.post("/eintraege",{faehre:faehre.value,startdatum:startdatum.value,endedatum:endedatum.value,kategorieFilter:kategorieFilter.value,showAllFaehren:showAllFaehren.value},(data) => {
        console.log(data)
        eintraege.value=data.eintraege
        dauer.value = data.dauer
        

      })
    }

    reload()

  function getDate(date){
    // console.log(date)
    return moment(date).format("DD.MM.YYYY")
  }

function setMonth(anzahl){
  console.log("setMonth",anzahl,moment(startdatum.value).format("YYYY-MM-DD"))
  startdatum.value = moment(startdatum.value).add(anzahl,"month").toDate()
  endedatum.value = moment(endedatum.value).add(anzahl,"month").toDate()
  reload()
}

    return {
      goTo,
      eintraege,
      reload,
       faehre,
       addNewEintrag,
       getDate,
       startdatum,
       endedatum,
       setMonth,
       dauer,
       kategorieFilter,
       eintraegeComputed,
       setGasOelBestand,
       showAllFaehren
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
</style>