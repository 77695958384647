import {createRouter,createWebHistory} from 'vue-router' 
// import {inject} from 'vue' 

 import Shortcuts from '../views/Shortcuts'
 import Login from '../views/Login'
 import Logbuch from '../views/Logbuch'
 import Eintrag from '../views/Eintrag'
 import Home from '../views/Home'
//  import h from '@/plugins/helperPlugin'
 import h from '@/services/helpers'
import  storage from  "@/services/storage"

 const routes = [
 

     {
        path:"/shortcuts",
        name:"Shortcuts",
        component:Shortcuts,
        meta: { requiresAuth: true },
      },
      {
        path:"/eintrag/:eintrag_id",
        name:"Eintrag",
        component:Eintrag,
        meta: { requiresAuth: true },
     },
      {
        path:"/logbuch",
        name:"Logbuch",
        component:Logbuch,
        meta: { requiresAuth: true },
     },

     {
        path:"/",
        name:"Startseite",
        component:Home,
        meta: { requiresAuth: true },
     },
     {
        path:"/login",
        name:"login",
        component:Login,
        meta: { requiresAuth: false },
     }
     
 ]

// let isLoggedIn = function(){

//      console.log("isLoggedIn")
//      let user = localStorage.getItem("user");
//      if(user!==null && typeof(user)!="undefined"){
//          return true;
//      }
 
//      return false;
//  }

 const router = createRouter({
     history:createWebHistory(process.env.BASE_URL),
     routes,
    })
    router.replace({ path: '*', redirect: '/abgleichmaerkte' })

    router.beforeEach((to, from, next) => {

     // console.log("beforeEach",to)
     if (to.matched.some(record => record.meta.requiresAuth_promoter)) {
          console.log("Yeachh. I neede a promoter auth")

          // const storage =inject("storage")
          console.log('storage.get("user"');
          if(storage.get("promoter") ==  null){

               next({ name: 'Login' })
          }else{
               next()
          }
     }


     if (to.matched.some(record => record.meta.requiresAuth)) {
       // this route requires auth, check if logged in
       // if not, redirect to login page.

     //   console.log("123")
       if (!h.isLoggedIn()) {
         next({ name: 'Login' })
       } else {
         next() // go to wherever I'm going
       }
     } else {
       next() // does not require auth, make sure to always call next()!
     }
   })

    export default router