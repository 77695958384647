<template>
    <header>

        <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">
          <!-- <img src="@/assets/img/logo.png" style="height:40px" /> -->
          <router-link class="nav-link" to="/" ><img src="@/assets/img/logo.png" style="font-size:8px;margin-left:-15px;margin-top:-3px;height:40px;float:left" >{{getRealNameForFaehre(faehre)}}</router-link> 
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavBar" aria-controls="myNavBar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>




  <div class="collapse  navbar-collapse" id="myNavBar">
    <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
        <router-link class="nav-link" to="/" data-toggle="collapse"  data-target="#myNavBar">Home</router-link> 
      </li>
      
         <li class="nav-item active">
        <router-link class="nav-link" to="/logbuch" data-toggle="collapse"  data-target="#myNavBar">Logbuch</router-link> 
      </li>
      
      <li class="nav-item active">
        <router-link class="nav-link" to="/shortcuts" data-toggle="collapse" data-target="#myNavBar">Shortcuts</router-link>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="" v-on:click="logout"  data-toggle="collapse" data-target="#myNavBar" >Logout</a>
      </li>

      <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          Menü
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <router-link class="dropdown-item" to="/startseite">Startseite</router-link>
          <router-link class="dropdown-item" to="/logbuch">Logbuch</router-link>
          <router-link class="dropdown-item" to="/shortcuts">Shortcuts</router-link>
          <router-link class="dropdown-item" to="/login">Login</router-link>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="" v-on:click="logout"  data-toggle="collapse" data-target="#myNavBar" >Logout</a>
        </div>
      </li> -->
    </ul>

  </div>
</nav>


<teleport v-if="pleasTeleport" to="#suchergebnisseFromHeader">

 <!-- {{promotorenListe}}  -->

 <w-table class="cctable"

        :headers="[ 
        { label: 'Vorname', key: 'vorname' },
        { label: 'Nachname', key: 'nachname' }
        ]"
        :items="promotorenListe">
   <template #item="{ item,  select, classes }">
    <tr  :class="classes" @click="select">
      <td>{{item.vorname}} </td>
      <td>{{item.nachname}} </td>
    </tr>
  </template>
</w-table>



</teleport>
    </header>

    

</template>








<script>




import router from '../router'
import { useStore  } from 'vuex'
import {ref,inject } from 'vue'

export default{
    setup(){
      const web =inject("web")
      const storage =inject("storage")
      const store = useStore()

      let faehre=ref("")
      faehre.value=storage.get("faehre")
      let faehren=storage.get("faehren")


      let pleasTeleport = ref(false)
      let suchbegriff = ref("")
      let promotorenListe = ref([])
      let errors =( [])
           
        // pleasTeleport.value=!pleasTeleport.value;
        

        web.get("/shortcuts",(data) =>{
          console.log(data);
          storage.set("shortcuts",data.shortcuts)
          // promotorenListe.value=data.promoter
          // router.push('home')

        })
        // console.log(e.target)

      

 function logout(){
          // LocalStorage leeren
          storage.delete("promoter")
          storage.delete("user")
          // Den User im Status entfenern
          store.state.user= false
          store.state.promoter=false
          // Umleitun
          router.push('/login')
        }


function getRealNameForFaehre(faehre){
  
    console.log("getRealNameForFaehre storage.faehren",storage.get("faehren"))
    // if(storage.get("dsads")=="undefined"){
      return faehren[faehre]
    // }  

// ''    switch(faehre){

  //     case "linzremagen":
  //       return "Linz-Remagen"
  //     case "stjohannes":
  //       return "St. Johannes"        
  //     case "nixe":
  //       return "Nixe"
  //     case "stmartin":
  //       return "St. Martin"
  //     case "sonstiges":
  //       return "Sonstiges"

  // }
}
  // function amILoggedIn(){
  //    return store.getters.isLoggedIn
  //   }
      return{
        getRealNameForFaehre,
        promotorenListe,
        // amILoggedIn,
        // counter: computed(() => store.state.counter),
        // user: computed(() => store.state.user),
        // $store : store,
        pleasTeleport,
        suchbegriff,
        errors,
        logout,
        faehre
      }
    },


    props:{
        'title' :{type:String,default:"NMope"}
    },
    

  
    components:{
        // Button
    }
  
}
</script>

<style scoped>




.router-link-active .router-link-exact-active {
    color:#00ce00;
}

/* nav a:hover, */
 nav a.router-link-active,
 nav a.router-link-exact-active {
   /* background-color: indianred; */
   text-decoration: underline;;
   cursor: pointer;
 }
 nav, .dropdown-menu{
   /* background-color:#fa931d  !important; */
   background-color:#FFF  !important;
   color:#333;
 }
 nav >*  a{
   color:#333 !important;
 }
 

 /** Wird teleportiert, ist aber trotzdem hier */
.cctable{
    border:0px;
}

.cctable td{
    padding:10px
}
</style>